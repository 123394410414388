/* .home-heading {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ED5F22 !important;
    width: 73%;
}

.home-p {
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ED5F22 !important;
}

.home-setion-1 {
    display: grid;
    grid-template-columns: 0.9fr 1fr;
    margin-top: 5%;
    bottom: -245px;
    margin-left: 8%;
}

.home-buttons {
    display: flex;
    align-items: center;
    width: 55%;
    margin-top: 10%;
    border: 0;
}

.home-container img {
    height: 766px;
}

.home-button-1, .home-button-2 {
    background-color: #fff;
    color: #ED5F22;
    font-family: "Roboto Mono", monospace;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 24px;
    margin-right: 20%;
}

.home-container-2 {
    padding-top: 18%;
}

.home-section-2 .home-section-2-heading {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.home-section-2-p {
    text-align: center;
    margin-left: 8%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 8%;
}

.home-section-2 {
    margin-top: 5%;
}

.icons-set-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

ul.icons-set-1-ul {
    display: flex;
    justify-content: space-around;
    width: 50%;
} */

.home-heading {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ED5F22 !important;
    width: 73%;
}

.home-p {
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ED5F22 !important;
}

.home-setion-1 {
    display: grid;
    grid-template-columns: 0.9fr 1fr;
    margin-top: 5%;
    margin-left: 8%;
}

.home-buttons {
    display: flex;
    align-items: center;
    width: 55%;
    margin-top: 10%;
    border: 0;
}

.home-container img {
    height: 766px;
}

.home-button-1, .home-button-2 {
    background-color: #fff;
    color: #ED5F22;
    font-family: "Roboto Mono", monospace;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    margin-right: 20%;
}

.home-container-2 {
    padding-top: 18%;
}

.home-section-2 .home-section-2-heading {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.home-section-2-p {
    text-align: center;
    margin-left: 8%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 8%;
}

.home-section-2 {
    margin-top: 5%;
}

.icons-set-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

ul.icons-set-1-ul {
    display: flex;
    justify-content: space-around;
    width: 50%;
}

/* Media Queries */
@media (max-width: 1024px) {
    .home-setion-1 {
        grid-template-columns: 1fr;
        margin-left: 5%;
    }

    .home-container img {
        height: 600px;
    }

    .home-heading {
        font-size: 35px;
    }

    .home-p {
        font-size: 18px;
    }

    .home-buttons {
        width: 70%;
        margin-top: 8%;
    }
}

@media (max-width: 768px) {
    .home-setion-1 {
        grid-template-columns: 1fr;
        margin-left: 5%;
    }

    .home-container img {
        height: 500px;
    }

    .home-heading {
        font-size: 30px;
    }

    .home-p {
        font-size: 16px;
    }

    .home-buttons {
        width: 80%;
        margin-top: 6%;
        flex-direction: column;
    }

    .home-button-1, .home-button-2 {
        margin-right: 0;
        margin-bottom: 10px;
        width: 80%;
    }

    .home-container-2 {
        padding-top: 10%;
    }

    .home-heading {
        font-family: "Roboto Mono", monospace;
        font-size: 40px;
        color: #ED5F22 !important;
        width: 98%;
    }

    .home-buttons {
        width: 100%;
    }

    ul.icons-set-1-ul {
        width: 90%;
    }

    .icons-set-1-li img {
        width: 80px;
    }

    .home-heading {
        padding-left: 20px;
    }

    .home-p {
        text-align: center;
    }

}

@media (max-width: 576px) {
    .home-setion-1 {
        grid-template-columns: 1fr;
        margin-left: 2%;
    }

    .home-container img {
        height: 400px;
    }

    .home-p {
        font-size: 14px;
    }

    .home-buttons {
        width: 90%;
        margin-top: 5%;
        flex-direction: column;
    }

    .home-button-1, .home-button-2 {
        margin-right: 0;
        margin-bottom: 10px;
        width: 90%;
    }

    .home-container-2 {
        padding-top: 8%;
        padding-left: 16px;
    }

    .home-section-2 .home-section-2-heading {
        font-size: 24px;
    }
    .home-section-2-p {
        font-size: 16px;
    }

    .home-heading {
        font-size: 16px;
        width: 350px;
    }

    .icons-set-1-li img{
        width: 40px;
    }

    ul.icons-set-1-ul {
        width: 90%;
    }

}

@media (max-width: 374px) {
    .home-setion-1 {
        grid-template-columns: 1fr;
        margin-left: 1%;
    }

    .home-container img {
        height: 300px;
    }

    .home-heading {
        font-size: 20px;
        width: 100%;
    }

    .home-p {
        font-size: 12px;
    }

    .home-buttons {
        width: 100%;
        margin-top: 4%;
        flex-direction: column;
    }

    .home-button-1, .home-button-2 {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .home-container-2 {
        padding-top: 5%;
    }
    
    .home-section-2 .home-section-2-heading {
        font-size: 20px;
    }

    .home-section-2-p {
        font-size: 14px;
        margin-left: 5%;
        margin-right: 5%;
    }

    ul.icons-set-1-ul {
        width: 90%;
        flex-wrap: wrap;
    }

    ul.icons-set-1-ul li {
        margin: 5px;
    }

    .icons-set-1-li img{
        width: 38px;
    }
}
