/* .products-heading h1 {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.products-heading p {
    text-align: center;
    margin-left: 20%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 20%;
}

.products-card img {
    width: 498px;
}

.products-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    margin: 30px;
}

.products-card-heading > h1 {
    text-align: center;
    font-family: "Roboto Mono", monospace;
    font-size: 30px;
    color: #ffffff !important;
}

.products-card-heading > p {
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    text-align: center;
    width: 90%;
}

.products-container {
    margin-top: 5%;
}

.products-card-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 10% 0 10%;
}

.products-card.zig {
    display: flex;
    flex-direction: row-reverse;
} */


.products-heading h1 {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.products-heading p {
    text-align: center;
    margin-left: 20%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 20%;
}

.products-card img {
    width: 498px;
}

.products-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    margin: 30px;
}

.products-card-heading > h1 {
    text-align: center;
    font-family: "Roboto Mono", monospace;
    font-size: 30px;
    color: #ffffff !important;
}

.products-card-heading > p {
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    text-align: center;
    width: 90%;
}

.products-container {
    margin-top: 5%;
}

.products-card-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 10%;
}

.products-card.zig {
    display: flex;
    flex-direction: row-reverse;
}

/* Media Queries */
@media (max-width: 1024px) {
    .products-heading p {
        margin-left: 10%;
        margin-right: 10%;
    }

    .products-card {
        grid-template-columns: 1fr;
    }

    .products-card img {
        width: 80%;
    }

    .products-card.zig {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .products-heading p {
        margin-left: 5%;
        margin-right: 5%;
    }

    .products-card {
        grid-template-columns: 1fr;
    }

    .products-card img {
        width: 70%;
    }

    .products-card-heading > h1 {
        font-size: 25px;
        margin-top: 15px;
    }

    .products-card-heading > p {
        font-size: 18px;
    }

    .products-card {
        margin: 0;
    }

    .products-card-heading > p {
        width: 100%;
    }


}

@media (max-width: 576px) {
    .products-heading h1 {
        font-size: 30px;
    }

    .products-heading p {
        margin-left: 2%;
        margin-right: 2%;
        font-size: 18px;
    }

    .products-card img {
        width: 100%;
    }

    .products-card-heading > h1 {
        font-size: 20px;
        margin-top: 15px;
    }

    .products-card-heading > p {
        font-size: 16px;
        width: 19rem;
    }

    .products-card {
        margin: 0;
    }

    .products-heading p {
        font-size: 14px;
    }
}

@media (max-width: 374px) {
    .products-heading h1 {
        font-size: 24px;
    }

    .products-heading p {
        margin-left: 1%;
        margin-right: 1%;
        font-size: 16px;
    }

    .products-card img {
        width: 100%;
    }

    .products-card-heading > h1 {
        font-size: 18px;
    }

    .products-card-heading > p {
        font-size: 14px;
    }

    .products-card {
        margin: 10px;
        grid-gap: 5%;
    }

    .products-card-container {
        margin: 0;
    }

    .products-card-heading > p {
        width: 17rem;
    }
    
    .products-card-heading > h1 {
        margin-top: 15px;
    }

    .products-heading p {
        font-size: 14px;
    }
}
