/* .about-us-heading h1 , .about-us-card-heading h1 {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.about-us-heading p , .about-us-card-heading p {
    text-align: center;
    margin-left: 20%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 20%;
}

.about-us-card {
    width: 50%;
    padding-top:3%
}

.about-us-card-container {
    display: flex;
    margin-top: 5%;
}

.about-us-card-heading p {
    font-size: 24px;
    text-align: start;
}

.about-us-card.card-1 {
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    grid-gap: 8%;
}

.about-us-card.card-2 {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    grid-gap: 5%;
}

.card-3 .about-us-card-heading {
    padding-top: 5%;
}

.about-us-card.card-3 {
    display: flex;
    flex-direction: column;
    grid-gap: 5%;
}

.card-3-container .about-us-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8%;
}

.about-us-card-container.card-2-zig-zag {
    flex-direction: row-reverse;
    padding-right: 10%;
}

.about-us-container-1 {
    margin-top: 5%;
} */

.about-us-heading h1, .about-us-card-heading h1 {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.about-us-heading p, .about-us-card-heading p {
    text-align: center;
    margin-left: 20%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 20%;
}

.about-us-card {
    width: 50%;
    padding-top: 3%;
}

.about-us-card-container {
    display: flex;
    margin-top: 5%;
}

.about-us-card-heading p {
    font-size: 24px;
    text-align: start;
}

.about-us-card.card-1 {
    padding-top: 8%;
    display: flex;
    flex-direction: column;
    grid-gap: 8%;
}

.about-us-card.card-2 {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    grid-gap: 5%;
}

.card-3 .about-us-card-heading {
    padding-top: 5%;
}

.about-us-card.card-3 {
    display: flex;
    flex-direction: column;
    grid-gap: 5%;
}

.card-3-container .about-us-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8%;
}

.about-us-card-container.card-2-zig-zag {
    flex-direction: row-reverse;
    padding-right: 10%;
}

.about-us-container-1 {
    margin-top: 5%;
}

/* Media Queries */
@media (max-width: 1024px) {
    .about-us-heading p, .about-us-card-heading p {
        margin-left: 10%;
        margin-right: 10%;
    }

    .about-us-card {
        width: 100%;
    }

    .about-us-card-container {
        flex-direction: column;
        align-items: center;
    }

    .about-us-card-container.card-2-zig-zag {
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .about-us-heading p, .about-us-card-heading p {
        margin-left: 5%;
        margin-right: 5%;
    }

    .about-us-card-container {
        flex-direction: column;
        align-items: center;
        margin-top: 5%;
    }

    .about-us-card-heading p {
        font-size: 20px;
        text-align: center;
    }

    .about-us-card-container.card-2-zig-zag {
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }
}

@media (max-width: 576px) {
    .about-us-heading h1, .about-us-card-heading h1 {
        font-size: 30px;
    }

    .about-us-heading p, .about-us-card-heading p {
        margin-left: 2%;
        margin-right: 2%;
        font-size: 18px;
    }

    .about-us-card {
        width: 100%;
    }

    .about-us-card-container {
        flex-direction: column;
        align-items: center;
    }

    .about-us-card-heading p {
        font-size: 18px;
        text-align: center;
    }

    .about-us-card-container.card-2-zig-zag {
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }
}

@media (max-width: 374px) {
    .about-us-heading h1, .about-us-card-heading h1 {
        font-size: 24px;
    }

    .about-us-heading p, .about-us-card-heading p {
        margin-left: 1%;
        margin-right: 1%;
        font-size: 16px;
    }

    .about-us-card {
        width: 100%;
    }

    .about-us-card-container {
        flex-direction: column;
        align-items: center;
    }

    .about-us-card-heading p {
        font-size: 16px;
        text-align: center;
    }

    .about-us-card-container.card-2-zig-zag {
        flex-direction: column;
        align-items: center;
        padding-right: 0;
    }
}
