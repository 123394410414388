/* .services-heading h1 {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.services-heading p {
    text-align: center;
    margin-left: 20%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 20%;
}

.services-card img {
    width: 498px;
}

.services-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    margin: 30px;
}

.services-card-heading > h1 {
    text-align: center;
    font-family: "Roboto Mono", monospace;
    font-size: 30px;
    color: #ffffff !important;
}

.services-card-heading > p {
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    text-align: center;
    width: 90%;
}

.services-container {
    margin-top: 5%;
}

.services-card-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 10% 0 10%;
}

.services-card.zig {
    display: flex;
    flex-direction: row-reverse;
} */

.services-heading h1 {
    font-family: "Roboto Mono", monospace;
    font-size: 40px;
    color: #ffffff !important;
    text-align: center;
}

.services-heading p {
    text-align: center;
    margin-left: 20%;
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    margin-right: 20%;
}

.services-card img {
    width: 498px;
}

.services-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    margin: 30px;
}

.services-card-heading > h1 {
    text-align: center;
    font-family: "Roboto Mono", monospace;
    font-size: 30px;
    color: #ffffff !important;
}

.services-card-heading > p {
    font-family: "Roboto Mono", monospace;
    font-size: 20px;
    color: #ffffff !important;
    text-align: center;
    width: 90%;
}

.services-container {
    margin-top: 5%;
}

.services-card-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 10%;
}

.services-card.zig {
    display: flex;
    flex-direction: row-reverse;
}

/* Media Queries */
@media (max-width: 1024px) {
    .services-heading p {
        margin-left: 10%;
        margin-right: 10%;
    }

    .services-card {
        grid-template-columns: 1fr;
    }

    .services-card img {
        width: 80%;
    }

    .services-card.zig {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .services-heading p {
        margin-left: 5%;
        margin-right: 5%;
    }

    .services-card {
        grid-template-columns: 1fr;
    }

    .services-card img {
        width: 70%;
    }

    .services-card-heading > h1 {
        font-size: 25px;
        margin-top: 15PX;
    }

    .services-card-heading > p {
        font-size: 18px;
    }

    .services-card {
        margin: 0;
    }

    .services-card-heading > p {
        width: 100%;
    }

}

@media (max-width: 576px) {
    .services-heading h1 {
        font-size: 30px;
    }

    .services-heading p {
        margin-left: 2%;
        margin-right: 2%;
        font-size: 18px;
    }

    .services-card img {
        width: 100%;
    }

    .services-card-heading > h1 {
        font-size: 20px;
        margin-top: 15px;
    }

    .services-card-heading > p {
        font-size: 16px;
        width: 19rem;
    }

    .services-card {
        margin: 0;
    }

    .services-heading p {
        font-size: 14px;
    }


}

@media (max-width: 374px) {
    .services-heading h1 {
        font-size: 24px;
    }

    .services-heading p {
        margin-left: 1%;
        margin-right: 1%;
        font-size: 16px;
    }

    .services-card img {
        width: 100%;
    }

    .services-card-heading > h1 {
        font-size: 18px;
    }

    .services-card-heading > p {
        font-size: 14px;
    }

    .services-card {
        margin: 10px;
        grid-gap: 5%;
    }

    .services-card-container {
        margin: 0;
    }

    .services-card-heading > p {
        width: 17rem;
    }
    
    .services-card-heading > h1 {
        margin-top: 15px;
    }

    .services-heading p {
        font-size: 14px;
    }

}
