/* .footer {
    background-color: white;
    max-height: fit-content;
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    color: black;
    position: absolute;
    bottom: 0;
}

.logo {
    height: 40px;
    margin-top: 7px;
    margin-bottom: 15px;
    left: 5%;
}

.footer {
    background-color: white;
    max-height: -moz-fit-content;
    max-height: fit-content;
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
    color: black;
    position: relative;
    bottom: 0;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
}

h6 {
    color: orange;
}

.sub-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: left;
    grid-gap: 8%;
}

.icon {
    font-size: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.contactus {
    max-width: 400px;
    margin: 0;
}

@media (max-width: 768px) {
    .sub-footer {
        flex-direction: column;
        align-items: center;
    }

    .footer1, .contactus {
        max-width: 100%;
        text-align: center;
    }

    .icon {
        justify-content: center;
    }
} */

/* Footer CSS */
.footer {
    background-color: white;
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    color: black;
    position: relative;
    bottom: 0;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.logo {
    height: 40px;
    margin-top: 7px;
    margin-bottom: 15px;
    left: 5%;
}

h6 {
    color: orange;
}

.sub-footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: left;
    grid-gap: 8%;
}

.icon {
    font-size: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.contactus {
    max-width: 400px;
    margin: 0;
}

@media (max-width: 1024px) {
    .sub-footer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .sub-footer {
        grid-template-columns: 1fr;
        text-align: center;
        grid-gap: 1%;
    }

    .footer1, .contactus {
        max-width: 100%;
        text-align: center;
    }

    .icon {
        justify-content: center;
    }

}

@media (max-width: 576px) {
    .footer {
        padding-left: 5%;
        padding-right: 5%;
        font-size: 12px;
        height: 46rem;
    }

    .sub-footer {
        grid-template-columns: 1fr;
        grid-gap: 1%;
    }

    .footer1, .contactus {
        max-width: 100%;
        text-align: center;
    }

    .icon {
        justify-content: center;
    }
 
}

@media (max-width: 374px) {
    .footer {
        padding-left: 2%;
        padding-right: 2%;
        font-size: 10px;
        height: 43rem;
    }

    .sub-footer {
        grid-template-columns: 1fr;
        grid-gap: 1%;
    }

    .app-container {
        padding-left: 3%;
        padding-right: 3%;
    }

    .footer1, .contactus {
        max-width: 100%;
        text-align: center;
    }

    .icon {
        justify-content: center;
    }

    .footer {
        padding-left: 2%;
        padding-right: 2%;
        font-size: 12px;
        height: 49rem;
    }
}

